.item-categories {
  &_btns {
    display: flex;
    justify-content: end;
    @include media-breakpoint-down(lg) {
      display: block;
    }
    .btn {
      min-width: 9.625rem;
    }
    a {
      @include media-breakpoint-down(lg) {
        width: 100%;
      }
    }
  }
}
