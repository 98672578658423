.dropdown-btn {
  width: 1.5rem;
  height: 1.5rem;
  background-color: #f0f0f0;
  border-radius: 50%;
  display: flex;
  position: relative;
  svg {
    position: absolute;
    top: 0.6rem;
    left: 0.5rem;
  }
  cursor: pointer;
  &--up {
    svg {
      transform: rotate(180deg);
      top: 0.5rem;
      left: 0.5rem;
    }
  }
}
