.error-box2 {
  background-color: #fff9e5;
  color: $yellow-600;
  width: 100%;
  margin: 0.5rem 0;
  border-radius: 6px;
  padding: 0.5rem;
  font-size: 0.8125rem;
}
.error-text {
  margin-bottom: 0.625rem;
  color: $yellow-600;
  font-size: 0.8125rem;
}
.dc-warning {
  background-color: #fff9e5;
  color: $yellow-600;
  width: 100%;
  margin: 0.5rem 0;
  border-radius: 6px;
  padding: 0.8rem;
  font-size: 0.9rem;
}
