.breadcrumbs {
  font-size: 0.875rem;
  color: $soft;
  display: flex;
  align-items: center;
  a {
    color: $soft;
    text-decoration: none;
  }
  .active {
    text-decoration: none;
    color: $black;
  }
}
