.proof-loss-list {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  min-height: 7.7rem;
  @include media-breakpoint-down(lg) {
    display: block;
  }
  &__item {
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    @include media-breakpoint-down(lg) {
      margin: 0.5rem 0;
    }
    // &:first-child {
    //     margin-left: 7.375rem;
    //     @include media-breakpoint-down(lg) {
    //         margin-left: 0;
    //     }
    // }
  }
  &.modifyable {
  }
  &__btn-upload {
    margin-right: 0.5rem;
    position: absolute;
    top: 0;
    left: 0;
    @include media-breakpoint-down(lg) {
      position: static;
    }
  }
}
.proof-loss-list.modifyable {
  .proof-loss-list__item {
    &:first-child {
      margin-left: 11.175rem;
      @include media-breakpoint-down(lg) {
        margin-left: 0;
      }
    }
  }
}
